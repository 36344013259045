.cld-video-player {
  // Default captions styles
  // See styled-text-tracks plugin for more options
  .vjs-text-track-display {
    // When control-bar is shown
    bottom: 5em;
    z-index: 2;
    // Word highlight
    &.cld-paced-text-tracks b {
      color: var(--color-accent);
    }
  }
  &.vjs-controls-disabled .vjs-text-track-display,
  &.vjs-user-inactive.vjs-playing .vjs-text-track-display {
    // When control-bar is hidden/disabled
    bottom: 1em;
  }

  .vjs-text-track-cue {
    max-width: 100%;
    > div {
      display: inline-block !important;
      padding: 0.1em 0.3em;
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
  }

  // Word highlight - default style
  .cld-paced-text-tracks .vjs-text-track-cue b {
    color: var(--color-accent);
  }

  // Default caption styles, when not configured to `videojs-default` theme
  .vjs-text-track-display:not(.cld-styled-text-tracks-theme-videojs-default) {
    .vjs-text-track-cue {
      font-family: inherit !important;
      > div {
        font-weight: 700;
        background-color: transparent !important;
        text-shadow: 0 0 0.2em rgba(0, 0, 0, 0.8);
        border-radius: 0.2em;
      }
    }
  }
}
