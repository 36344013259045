.cld-video-player {
  .vjs-control-bar-chapter-wrapper {
    display: flex;
    align-items: center;
    container-type: inline-size;
  }
  .vjs-control-bar-chapter-display {
    line-height: 1.5;
    font-size: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0.5em;
    &:not(:empty)::before {
      content: '•';
      padding-right: 0.5em;
    }
    @container (max-width: 150px) {
      display: none;
    }
  }

  .vjs-chapter-marker {
    pointer-events: none;
    position: absolute;
    background: var(--color-base);
    width: 4px;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: 1;
  }

  .vjs-chapter-display {
    pointer-events: none;
    line-height: 1.5;
    font-size: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translateX(-50%);
    bottom: 2.7em;
    position: absolute;
    padding: 0 0.3em;
    font-weight: bold;
    text-shadow: 0 0 8px var(--color-base),  0 0 1px var(--color-base),  0 0 1px var(--color-base);
    &:not(:empty) ~ .vjs-vtt-thumbnail-display {
      bottom: 4em;
    }
  }

  .vjs-time-tooltip {
    right: auto !important;
    translate: -50%;
  }
}
