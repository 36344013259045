@use "sass:color";
.cld-video-player {

  // Gravity (position + alignment)
  .cld-styled-text-tracks-gravity-center {
    inset: 0 !important;
    .vjs-text-track-cue {
      inset: 0 !important;
      margin: auto;
    }
  }

  .cld-styled-text-tracks-gravity-top {
    .vjs-text-track-cue {
      top: 0 !important;
    }
  }

  .cld-styled-text-tracks-gravity-right {
    .vjs-text-track-cue {
      text-align: right !important;
    }
  }

  .cld-styled-text-tracks-gravity-left {
    .vjs-text-track-cue {
      text-align: left !important;
    }
  }

  // Themes
  div.vjs-text-track-display.cld-styled-text-tracks-theme-yellow-outlined div.vjs-text-track-cue > div,
  &:has(.cld-styled-text-tracks-theme-yellow-outlined) ::cue {
    color: #FEF94A !important;
    text-shadow:
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;
  }

  div.vjs-text-track-display.cld-styled-text-tracks-theme-3d div.vjs-text-track-cue > div,
  &:has(.cld-styled-text-tracks-theme-3d) ::cue {
    $base-size: 0.03em;
    $base-color: #ff76ad;
    color: color.adjust($base-color, $lightness: 40%) !important;
    text-shadow:
      #{1*$base-size} #{1*$base-size} 0 color.adjust($base-color, $lightness: -10%),
      #{2*$base-size} #{2*$base-size} 0 color.adjust($base-color, $lightness: -20%),
      #{3*$base-size} #{3*$base-size} 0 color.adjust($base-color, $lightness: -30%),
      #{4*$base-size} #{4*$base-size} 0 color.adjust($base-color, $lightness: -40%);
  }

  div.vjs-text-track-display.cld-styled-text-tracks-theme-player-colors div.vjs-text-track-cue > div,
  &:has(.cld-styled-text-tracks-theme-player-colors) ::cue {
    color: var(--color-text) !important;
    background-color: var(--color-accent) !important;
    text-shadow: 0 0 4px var(--color-base);
  }
}
